import React from 'react';
import dp from '../assets/dp.png';

function Aboutme() {
  return (
    <div id='aboutMe' className='flex flex-col-reverse lg:flex-row justify-around items-center px-10 lg:px-20 py-28 text-left'>
      <div className='lg:w-1/2 h-1/2 md:w-2/3 sm:w-3/4 '>
        <p>My name is Dorsa, and I am a passionate and skilled developer with expertise in building modern, responsive, and user-friendly web applications.</p>
        <br />
        <p>I am excited to showcase my skills and experience through this portfolio, which includes a diverse range of projects that I have worked on. From designing beautiful and intuitive user interfaces to writing clean and efficient code, I strive to deliver high-quality solutions that meet the needs of my clients and users.</p>
        <br />
        <p>In addition, I am proud to hold both the <em className='underline'>
          <a target='_blank' rel='noopener noreferrer' href='https://scrimba.com/certificate/uKgd9dCN/gfrontend'>Scrimba</a></em> and the <em className='underline'>
          <a target='_blank' rel='noopener noreferrer' href='https://certificate.algoexpert.io/FrontendExpert%20Certificate%20FE-0bea2fd697'>FrontendExpert</a></em> certificates, which validate my proficiency in front-end web development. These programs have equipped me with valuable skills in HTML, CSS, JavaScript, React.js Framework, and other essential front-end technologies, allowing me to create robust and dynamic web applications.</p>
        <br />
        <p>Feel free to contact me if you have any questions or inquiries. Thank you for your time, and I look forward to connecting with you!</p>
      </div>
      <div className='shrink-0 w-1/2 h-1/2 lg:w-1/3 lg:h-1/3  flex justify-center m-2 p-2 pb-10 '>
        <img className='rounded-full' src={dp} alt='profile' /> 
      </div>
    </div>
  )
}




export default Aboutme
