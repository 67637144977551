import React from 'react';
import {projects} from '../data';

function Projects() {

  function Card({ name, description, image, link, gitRepo, tools }) {
    return (
      <div className='card border-double border-4 border-blue-600 bg-blue-400/25 rounded-3xl flex flex-col w-full h-full box-content'>
        <div className='h-full flex justify-between'>
          <div className=' flex flex-col pt-4 pl-4 text-left w-5/6'>
            <h2 className='font-bold'> {name} </h2>
            <p className='pt-3'> {description} </p>
          </div>
          <div className=' p-2 w-full h-full'>
            <a target='_blank' rel='noopener noreferrer' href={link}><img className='p-2 rounded-3xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ' src={image} alt={description}/></a>
            <a target='_blank' rel='noopener noreferrer' href={gitRepo}><button className='hover:text-sky-600 p-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Git Repo</button></a>
          </div>
        </div>
        <div className='m-0 p-1'>
          <h4>{tools}</h4>
        </div>
      </div>
    );
  }

  const cardList = projects.map((project, index) => (
    <Card
      key={index}
      name={project.name}
      description={project.description}
      image={project.image}
      link={project.link}
      gitRepo={project.gitRepo}
      tools={project.tools}
    />
  ));

    
  return (
    <div id='projects' className='pt-20 px-5 md:px-10 flex justify-center items-center'>
      <div className=' grid gap-8 lg:grid-cols-3 lg:grid-rows-2 md:grid-cols-2 md:grid-rows-4 sm:grid-cols-1 sm:grid-rows-6'>
        {cardList}
      </div>
    </div>
  )
}

export default Projects
