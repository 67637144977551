import React from 'react';
import AboutMe from '../sections/AboutMe';
import Projects from '../sections/Projects';
import HireMe from '../sections/HireMe';

function Body() {
  return (
    <div className=''> 
      <h1 className='font-semibold py-5 text-lg uppercase'>Welcome to my Portfolio!</h1>
      <AboutMe />
      <Projects />
      <HireMe />
    </div>
  )
}

export default Body
