import React, {useState} from 'react';

function Header({ themeMode, toggleThemeMode }) {

  const [showMenu, setShowMenu] = useState(false);

  return (
    
    <navbar className={`${themeMode === "dark" ? "bg-zinc-800 text-white" : "bg-blue-400 text-black"} flex justify-between items-center uppercase sticky top-0 m-0 h-14 px-16`} >
      <div>
        <h1 className='font-semibold'><a href='/'>Code Baaji</a></h1> 
      </div>

      <div className="lg:hidden">
        <button onClick={() => setShowMenu(!showMenu)} className=" flex items-center text-blue-600 p-3">
          <svg className="block h-4 w-4 fill-current hover:text-zinc-500" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Mobile menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
        <div className={`flex flex-col items-end translate-y-14 p-6 rounded-lg ${themeMode === "dark" ? "bg-zinc-800 text-white" : "bg-blue-400 text-black"} ${showMenu ? 'block' : 'hidden'}`}>
          <button onClick={() => setShowMenu(!showMenu)} className=" flex translate-x-6 text-blue-600 p-3">
            <svg class="h-5 w-5 cursor-pointer hover:text-zinc-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <ul className='flex flex-col items-start pt-6' >
            <li className='self-end'>
              <button onClick={toggleThemeMode} className={`rounded-full px-1 border-2 hover:border-zinc-500 ${themeMode === "dark" ? "border-zinc-100" : "border-zinc-900"}`} >
              {themeMode === "light" ? "🌚" : "🌞"} 
              </button>
            </li>
            <li className='hover:text-zinc-500 py-1'><a href='#aboutMe'>About Me</a></li>
            <li className='hover:text-zinc-500 py-1'><a href='#projects'>Projects</a></li>
            <li className='hover:text-zinc-500 py-1'><a href='#hireMe'>Hire Me</a></li>
          </ul>
        </div>
      </div>

      <ul className='hidden transform lg:flex lg:items-end lg:justify-end  lg:space-x-6'>
        <li className='hover:text-zinc-500 px-1'><a href='#aboutMe'>About Me</a></li>
        <li className='hover:text-zinc-500 px-1'><a href='#projects'>Projects</a></li>
        <li className='hover:text-zinc-500 px-1'><a href='#hireMe'>Hire Me</a></li>
        <li>
          <button onClick={toggleThemeMode} className={`rounded-full px-1 border-2 hover:border-zinc-500 ${themeMode === "dark" ? "border-zinc-100" : "border-zinc-900"}`} >
           {themeMode === "light" ? "🌚" : "🌞"} 
          </button>
        </li>
      </ul>
    </navbar>
  )
}

export default Header
