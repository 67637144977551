import React from 'react';

function HireMe() {

  
  return (
    <div id='hireMe' className=' h-screen pt-14 flex justify-around items-center'>
      <div className="bg-sky-600/50 block p-6 rounded-lg shadow-lg  w-3/4 md:w-1/2 lg:w-1/3 ">
        <form name='hireForm' target="_blank" action="https://formsubmit.co/er.dorsa@gmail.com" method="POST">
          <div className="form-group mb-6">
            <input type="text" name="name" className="form-control block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              m-0
              focus:text-gray-700 focus:bg-white focus:border-stone-800 focus:outline-none" id="exampleInput7"
              placeholder="Full Name" required />
          </div>
          <div className="form-group mb-6">
            <input type="email" name="email" className="form-control block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              m-0
              focus:text-gray-700 focus:bg-white focus:border-stone-800 focus:outline-none" id="exampleInput8"
              placeholder="Email Address" required/>
          </div>
          <div className="form-group mb-6">
            <textarea
            class="
              form-control
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              m-0
              focus:text-gray-700 focus:bg-white focus:border-stone-800 focus:outline-none
            "
            id="exampleFormControlTextarea13"
            rows="3"
            placeholder="Your Message"
            name="message"
            required
          ></textarea>
          </div>
          <button type="submit" className="
            w-full
            px-6
            py-2.5
            bg-sky-900
            text-white
            font-medium
            text-xs
            leading-tight
            uppercase
            rounded
            shadow-md
            hover:bg-stone-900 hover:shadow-lg
            focus:bg-stone-900 focus:shadow-lg focus:outline-none focus:ring-0
            active:bg-stone-900 active:shadow-lg
            transition
            duration-150
            ease-in-out">Send</button>
        </form>
      </div>
    </div>
  )
}

export default HireMe
