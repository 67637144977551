import snakeGame from './assets/snakeGame.png';
import speedType from './assets/speedType.png';
import popUp from './assets/popUp.png';
import tenzies from './assets/tenzies.png';
import emojis from './assets/emojis.png';
import RPG from './assets/RPG.png';


export const projects = [
    {
       index: 1,
       name: 'Tenzies',
       description: 'Roll the dice and match their faces until they are all the same. You can freeze them by clicking them.',
       image: tenzies,
       link: 'https://tenzies-73bu.onrender.com/',
       gitRepo: 'https://github.com/DoryFar/Tenzies',
       tools: 'HTML CSS JavaScript React.js', 
    }, 
    {
        index: 2,
        name: 'Speed Typing Game',
        description: 'Press start and see how many words you can type in 15 seconds.',
        image: speedType,
        link: 'https://doryfar.github.io/Speed-typing-game/',
        gitRepo: 'https://github.com/DoryFar/Speed-typing-game',
        tools: 'HTML CSS JavaScript React.js', 
     },
    {
        index: 3,
        name: 'Annoying Pop-Up',
        description: 'This pop-up is relentless about getting your information, there is no way out of it.',
        image: popUp,
        link: 'https://doryfar.github.io/Annoying-pop-up/',
        gitRepo: 'https://github.com/DoryFar/Annoying-pop-up',
        tools: 'HTML CSS JavaScript', 
     },
     {
        index: 4,
        name: 'Snake Game',
        description: 'How many apples can you feed the snake before the snake feeds on itself?',
        image: snakeGame,
        link: 'https://doryfar.github.io/SnakeGame/',
        gitRepo: 'https://github.com/DoryFar/SnakeGame',
        tools: 'HTML CSS JavaScript', 
     },
     {
        index: 5,
        name: 'Add or Remove Emojis',
        description: 'Add or remove emojis from the beginning or the end the emoji line.',
        image: emojis,
        link: 'https://doryfar.github.io/Add-Remove-Emojis/',
        gitRepo: 'https://github.com/DoryFar/Add-Remove-Emojis',
        tools: 'HTML CSS JavaScript', 
     },
     {
        index: 6,
        name: 'Role Playing Game',
        description: 'Roll the dice to see if the wizard can beat the three monsters and be victorious.',
        image: RPG,
        link: 'https://effortless-bonbon-2857d5.netlify.app/',
        gitRepo: 'https://github.com/DoryFar/RPG',
        tools: 'HTML CSS JavaScript', 
     }
]
