import './App.css';
import React, { useState } from "react";
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';


function App() {

  const [themeMode, setThemeMode] = useState("light");

  const toggleThemeMode = () => {
    if (themeMode === "light") {
      setThemeMode("dark");
    } else {
      setThemeMode("light");
    }
  };
  return (
    <div className="App font-mono">
      <div className={`bg-${themeMode === "light" ? "white" : "black"} text-${themeMode === "light" ? "black" : "white"} ${themeMode === "dark" ? "bg-zinc-600 text-white" : "bg-orange-100"}`}>
        <Header themeMode={themeMode} toggleThemeMode={toggleThemeMode}/>
        <Body />
        <Footer />
      </div>
    </div>
  );
}

export default App;